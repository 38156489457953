import {cookie} from '../web'
import {doWhenP} from '../utils'
import {getCacheItem, setCacheItem} from '../cache'
import {getRequestId, updateRequest} from '../dataLayer'

export interface VwoCache {
  uuid: string
  requestId: string
  expts?: VwoExperiment[]
}

export interface VwoExperiment {
  experiment_id: number
  variation_id: number
}

export interface VwoReqBody {
  vwo?: {
    uuid: string
  }
  vwo_experiments?: VwoExperiment[]
}

export const VWO_CACHE_KEY = 'vwoData'
export const VWO_UUID_KEY = '_vwo_uuid'

export function getVwoId(fallback = false): string | null {
  const uuid = cookie(VWO_UUID_KEY) || null
  if (!uuid && fallback) {
    return window?._vwo_uuid || null
  }
  return uuid
}

export function getVwoExperiments(): VwoExperiment[] {
  const regex = /^vis_opt_exp_(\d+)_combi=(\d+)$/
  const cookies = document.cookie.split('; ')
  const expts: VwoExperiment[] = []

  for (const c of cookies) {
    const res = c.match(regex)
    if (res?.length !== 3) continue
    expts.push({
      experiment_id: Number(res[1]),
      variation_id: Number(res[2]),
    })
  }

  return expts
}

export async function sendVwoData(requestId: string, fallback = false) {
  const req: VwoReqBody = {}
  const uuid = getVwoId(fallback)
  const expts = getVwoExperiments()
  const cache = (getCacheItem(VWO_CACHE_KEY) || {}) as VwoCache

  if (uuid && uuid !== cache.requestId && requestId !== cache.requestId) {
    req.vwo = {uuid}
  }

  if (requestId !== cache.requestId) {
    req.vwo_experiments = expts
  } else {
    const exptsMap: Record<number, number> = {}
    cache.expts?.forEach(({experiment_id, variation_id}) => {
      exptsMap[experiment_id] = variation_id
    })
    for (const expt of expts) {
      const v = exptsMap[expt.experiment_id]
      if (typeof v !== 'number' || v !== expt.variation_id) {
        if (req.vwo_experiments?.length) {
          req.vwo_experiments.push(expt)
        } else {
          req.vwo_experiments = [expt]
        }
      }
    }
  }

  if (!req.vwo && !req.vwo_experiments?.length) return

  updateRequest(requestId, req).then(() => {
    setCacheItem(VWO_CACHE_KEY, {
      requestId,
      uuid,
      expts: [...(req.vwo_experiments || []), ...(cache.expts || [])],
    })
  })
}

export async function run(maxInterval = 10000) {
  const handlePredicate = () => !!(getRequestId() && getVwoId(false))
  return doWhenP(handlePredicate, maxInterval)
    .then(() => sendVwoData(getRequestId(), false))
    .catch(() => sendVwoData(getRequestId(), true))
}
