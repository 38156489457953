import {RotationConfig} from '../RotationConfig'
import RotationReplacer from '../RotationReplacer'
import {doWhenP} from '../utils'
import {fetchTokenNumbers} from '../Mapi'
import {getRequestId, getPromoCode} from '../dataLayer'

export function getRotationConfig() {
  return window.MAPI?.config
}

export function getRotation() {
  return window.MAPI?.Rotation
}

export function replacePhoneNumbers() {
  return doWhenP(() => window.MAPI?.refreshNumbers, 1000).then(() => window.MAPI.refreshNumbers())
}

export function run() {
  return doWhenP(() => getPromoCode() && getRequestId() && getRotationConfig()).then(() => {
    window.MAPI.Rotation = window.MAPI.Rotation || new RotationConfig({})
    const repl = new RotationReplacer()
    const rot = window.MAPI.Rotation

    rot.setConfig(getRotationConfig())
    repl.setConfig(rot)

    window.MAPI.refreshNumbers = repl.replaceRotated.bind(repl)
    window.MAPI.watchForNumbers = repl.watchForReplacement.bind(repl)
    window.MAPI.replacePhoneNumbers = window.MAPI.refreshNumbers // renaming to not break backward compat
    window.MAPI.fetchTokenNumbers = fetchTokenNumbers

    return repl
  })
}
