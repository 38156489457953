import {doWhenP} from '../utils'
import {getFromCookies} from '../Mapi'
import {getRequestId, updateRequest, getFromDataLayer} from '../dataLayer'
import {getCacheItem, setCacheItem} from '../cache'

function stripUndesiredVariationText(variation) {
  // apparently we can't modify the variation name through convert,
  // so we get to handle any unwanted formations ourselves
  return variation.replace('Var #', '')
}

function checkForNewData() {
  const newData = {
    experiment_id: getFromDataLayer('experiment_id'),
    variation_name: getFromDataLayer('variation_name'),
  }
  if (!newData.experiment_id || !newData.variation_name) return []

  const lastSent = getCacheItem('sent_convert') || {}
  const {experiment_id: exp} = newData
  let {variation_name: variation} = newData
  variation = stripUndesiredVariationText(variation)

  // eslint-disable-next-line no-continue
  if (lastSent[exp]) return []

  lastSent[exp] = variation
  setCacheItem('sent_convert', lastSent)

  const r = getFromCookies('_conv_r')
  const s = getFromCookies('_conv_s')
  const v = getFromCookies('_conv_v')

  return [
    {
      request_id: getRequestId(),
      experiment_id: exp,
      variation,
      conv_r: r,
      conv_s: s,
      conv_v: v,
    },
  ]
}

function sendCprConvert(data) {
  return updateRequest(getRequestId(), {
    convert: data,
  })
}

function checkAndSend() {
  const data = checkForNewData()
  if (data.length === 0 || typeof data === 'undefined') {
    return Promise.resolve({})
  }

  return sendCprConvert(data)
}

export function run(interval = 1000) {
  return doWhenP(() => !!getRequestId()).then(() => {
    checkAndSend()
    setInterval(() => checkAndSend(), interval)
  })
}
