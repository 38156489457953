import * as Rotation from '../lib/additional/Rotation'
import {registerMapiUtils} from './allModules'
import {bootstrap} from '../index'
import {doWhenP} from '../lib/utils'
import {checkAndUpdateXRobots} from '../lib/xrobots'

/**
 * Config format
 *
 * Example minimal config:
 *
 * window.MAPI = window.MAPI || {};
 * window.MAPI.config = {
 *   brand: "BRAND",
 *   replacement: '.some_class'
 * };
 *
 * Full Configuration:
 * {
 *   // EITHER:
 *   // REQUIRED Brand abbreviation for the current site
 *   brand: "BRAND",
 *
 *   // OPTIONAL Lets you define a default rotation token other than the brand
 *   defaultToken: "OTHER",
 *
 *   // OPTIONAL lets you define a default promo code in the case of no kbid / promo_path_mapping mapi-side
 *   defaultPromoCode: 123456,
 *
 *   // Replacers do the actual work of replacing phone numbers on the page, and deciding which tokens
 *   // we should use.
 *   replacement: [
 *     {
 *       // REQUIRED Anything supported by document.querySelectorAll
 *       // These are the DOM parents under which we should do phone number replacement.
 *       // Yes, you could just do 'body', but don't; keeping things limited is best so we don't
 *       // end up replacing things we shouldn't (especially if not using the numbersToReplace whitelist)
 *       selectors: ['.some_class', 'span[data-replace-from]'],
 *
 *       // OR, if you only have one selector, you can just:
 *       selectors: '.some_class'
 *
 *       // OPTIONAL For example, a multi-branded site needs to rotate for multiple brands, and the information we
 *       // need to determine the brand can live in the DOM. Will inherit the default token
 *       // from the main config defaultToken, or the brand failing that.
 *       token: "OTHER",
 *
 *       // Formatters: "US" 123-456-7890, "USAddOne" 1-123-456-7890, "USDots" 123.456.7890,
 *       //             "USDotsAddOne" 1.123.456.7890, "USParen" (123) 456-7890
 *       // OPTIONAL, defaults to "US"
 *       formatter: "US",
 *       // Also, you may pass any function you like to format the number:
 *       formatter: function(phone) { var formatted = 'doSomeLogic'; return formatted; },
 *
 *       // OPTIONAL You can whitelist numbers to replace. If passed, we will not replace any numbers
 *       // in the document that do not appear in this list.
 *       numbersToReplace: ['123-456-7890'],
 *
 *       // OPTIONAL Provide an array of functions returning true or false, for whether this replacer should
 *       // run at all. Any filter returning false will reject
 *       filters: [function(foundNumber, token, domNode) {
 *          return window.someVariable === true;
 *       }]
 *     }
 *   ],
 *
 *   // OR: if you have super simple needs, and the defaults work:
 *   replacement: '.some_class',
 *
 *   // OR:
 *   replacement: ['.some_class', '.some_other_class', '#some_id']
 *
 *   // OPTIONAL CONFIGURATIONS
 *   // Below are very optional configurations, which are mostly intended for development / testing use
 *
 *   // Force a domain to send to MAPI, *and* the domain for any cookies set. Defaults to window.location.hostname
 *   forceDomain: 'somewhere.com',
 *
 *   // Force a path to send to MAPI. Defaults to window.location.pathname
 *   forcePath: '/some/path',
 *
 *   // Whether we want to use a "number of death" response from MAPI to replace numbers in the page.
 *   // You usually won't want this. Defaults to false.
 *   allowLastResortNumber: false,
 *
 *   // Whether we want to set up a setInterval that will continually watch the DOM for numbers
 *   // to replace
 *   watchForReplacement: false,
 *
 *   // Interval in ms to watchForReplacement
 *   watchInterval: 2000,
 * }
 */

/**
 * The script below should be added to the *bottom* of the page.
 *
 * "brand" should be the Clearlink brand abbreviation.
 * "replacement" should be some CSS selector(s) that will match elements in the page that will
 * contain a phone number to be replaced with a rotated one.
 *
 * <script type="text/javascript" src="//mapi.clearlink.com/js/cl-phone.min.js" async></script>
 * <script type="text/javascript">
 *   window.MAPI = window.MAPI || {};
 *   window.MAPI.config = {
 *     brand: "BRAND",
 *     replacement: ['.cl_phone']
 *   };
 * </script>
 */

// Update xrobots to noindex, nofollow if the domain is a bad-actor cloned site
function mainCallback() {
  registerMapiUtils()
  doWhenP(() => window.MAPI?.config).then(config => {
    bootstrap(config).then(Rotation.run)
  })
}
checkAndUpdateXRobots(mainCallback)
