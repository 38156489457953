// Update xrobots to noindex, nofollow if the domain is a bad-actor cloned site
function checkAndUpdateXRobots(callback) {
  // Allowed domains
  const allowedDomains = ['safewise.com', 'www.safewise.com', 'move.org', 'www.move.org']

  // Get the current domain the client is visiting
  const currentDomain = window.location.hostname

  // Get the canonical URL from the source code
  const canonicalElement = document.querySelector('link[rel="canonical"]')
  const canonicalUrl = canonicalElement ? new URL(canonicalElement.href) : null

  // Function to check if a string is an exact match to any allowed domain
  const domainMatchesAllowedDomain = string => allowedDomains.includes(string)

  // Check if current domain and canonical URL meet conditions
  const currentDomainMatch = domainMatchesAllowedDomain(currentDomain)
  const canonicalDomainMatch = canonicalUrl ? domainMatchesAllowedDomain(canonicalUrl.hostname) : false

  // If the current domain does not contain allowed domains but the canonical does
  if (!currentDomainMatch && canonicalDomainMatch) {
    // Get or create the xrobots meta element
    let robotsMeta = document.querySelector('meta[name="robots"]')
    if (!robotsMeta) {
      robotsMeta = document.createElement('meta')
      robotsMeta.setAttribute('name', 'robots')
      document.head.appendChild(robotsMeta)
    }

    // Update the value to "noindex, nofollow"
    robotsMeta.setAttribute('content', 'noindex, nofollow')
  } else {
    // Run the callback script
    callback()
  }
}

export {checkAndUpdateXRobots}
