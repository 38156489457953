import {doWhenP} from '../utils'
import {getRequestId, updateRequest} from '../dataLayer'
import {getCacheItem, setCacheItem} from '../cache'

interface GoogleSessionData {
  ga_session_id: string | null
  ga_session_number: string | null
}

const CACHED_KEY = 'ga_session_data'

export function getSessionData() {
  const data: GoogleSessionData = {
    ga_session_id: null,
    ga_session_number: null,
  }

  const [rawCookie, rawValue] = document.cookie.match(/_ga_[A-Za-z0-9]+=(.*?)(?:;|$)/) || ['', '']
  const match = rawCookie.match(/_ga_[A-Za-z0-9]+=GS\d\.\d\.(.+?)(?:;|$)/)

  if (match) {
    const parts = match[1].split('.')
    data.ga_session_id = parts.shift() || null
    data.ga_session_number = parts.shift() || null
  } else if (rawValue.length) {
    data.ga_session_id = rawValue
    data.ga_session_number = rawValue
  }

  return data
}

function handlePredicate() {
  return getRequestId() && Object.values(getSessionData()).every(v => !!v)
}

function sendSessionData() {
  const requestId = getRequestId()
  const sessionData = getSessionData()
  const cachedData = getCacheItem(CACHED_KEY)
  const data = {
    ...sessionData,
    request_id: requestId,
  }

  const sameReqId = typeof cachedData === 'object' && cachedData.request_id === requestId
  const sameGaId = typeof cachedData === 'object' && sessionData.ga_session_id === cachedData.ga_session_id
  if (sameReqId && sameGaId) return

  updateRequest(requestId, {ga_session_data: data}).then(() => {
    setCacheItem(CACHED_KEY, data)
  })
}

export async function run(expiration = 10000) {
  return doWhenP(handlePredicate, expiration, false)
    .then(() => sendSessionData())
    .catch(e => {
      console.log('error sending ga session ID: ', e)
    })
}
