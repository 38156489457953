import {getFromCookies} from '../Mapi'
import {getRequestId, updateRequest} from '../dataLayer'
import {parseUriQuery} from '../web'
import {doWhenP} from '../utils'
import {getCacheItem, setCacheItem} from '../cache'

export function run() {
  return doWhenP(() => getRequestId(), 10000).then(requestId => {
    const prevSent = getCacheItem('previousFbIds') || {}
    const fbId = parseUriQuery().fbclid
    if (!fbId || prevSent[fbId]) return

    setTimeout(() => {
      const c = getFromCookies('_fbc')
      const p = getFromCookies('_fbp')

      return updateRequest(requestId, {
        fbids: {
          request_id: requestId,
          fbid: fbId,
          fbc: c,
          fbp: p,
        },
      }).then(() => {
        const sent = getCacheItem('previousFbIds') || {}
        sent[fbId] = true
        setCacheItem('previousFbIds', sent)
      })
    }, 10000)
  })
}
