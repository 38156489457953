import {doWhenP} from '../utils'
import {parseUriQuery} from '../web'
import {getClientId} from './GoogleClientId'
import {updateRequest, getRequestId} from '../dataLayer'
import {getCacheItem, setCacheItem} from '../cache'

interface GclData {
  gclid?: string | null
  gclsrc?: string | null
  request_id?: string | null
  client_id?: string | null
}

const CACHED_KEY = 'google_data'

function captureGclData(): GclData {
  const {gclid = null, gclsrc = null} = parseUriQuery() || {}
  return {gclid: gclid as string, gclsrc: gclsrc as string}
}

function updateGoogleData({gclid, gclsrc}: GclData) {
  const clientId = getClientId()
  const requestId = getRequestId()
  const cachedData = getCacheItem(CACHED_KEY) || {}

  const data: GclData = {}
  if (gclid) data.gclid = gclid
  if (gclsrc) data.gclsrc = gclsrc
  if (clientId) data.client_id = clientId
  if (requestId) data.request_id = requestId

  const entries = Object.entries(data)
  const hasChanges = entries.some(([k, v]) => cachedData[k] !== v)

  /** Return if we don't have any data to send */
  if (!requestId || entries.length < 2 || !hasChanges) return

  updateRequest(requestId, {[CACHED_KEY]: data}).then(() => {
    setCacheItem(CACHED_KEY, {...cachedData, ...data})
  })
}

export function handlePredicate() {
  return !!(getRequestId() && getClientId())
}

export async function run(expiration = 10000) {
  /** Capture data before user navigates away */
  const gclData = captureGclData()

  return doWhenP(handlePredicate, expiration, true)
    .then(() => updateGoogleData(gclData))
    .catch(e => {
      console.log('update google data error: ', e)
    })
}
